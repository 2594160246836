<template>
  <div class="card-body">
    <form
      role="form"
      class="text-start mt-3"
      @submit.prevent="handleSubmit"
    >
      <div class="mb-3">
        <material-input
          id="username"
          type="text"
          label="Username"
          name="username"
          v-model="username"
          :isRequired=true
        />
      </div>
      <div class="mb-3">
        <material-input
          id="password"
          type="password"
          label="Password"
          name="password"
          v-model="password"
          :isRequired=true
        />
      </div>
      <material-switch
        id="rememberMe"
        name="Remember Me"
      >
      {{ t('PAGES.LOGIN_PAGE.REMEMBER_ME') }}
      </material-switch>
      <div class="text-center">
        <material-button
          class="my-4 mb-2"
          variant="gradient"
          color="success"
          full-width
          type="submit"
          :disabled="isFormSending || showLoader"
          :is-loading="showLoader"
        >
        <span>
          {{ t('PAGES.LOGIN_PAGE.LOGIN') }}
        </span>
        </material-button>
      </div>
      <p class="mt-4 text-sm text-center">
        {{ t('PAGES.LOGIN_PAGE.ALREADY_HAVE_NOT_ACCOUNT') }}
        <router-link
          :to="{ name: '' }"
          class="text-success text-gradient font-weight-bold"
        >
        {{ t('PAGES.LOGIN_PAGE.SIGNUP') }}
        </router-link>
      </p>
    </form>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue"
import MaterialSwitch from "@/components/MaterialSwitch.vue"
import MaterialButton from "@/components/MaterialButton.vue"

import { useI18n } from "vue-i18n"
import { maxLength, minLength, required } from "@vuelidate/validators"
import { mapGetters } from "vuex"

export default {
  name: "LoginForm",
  components: {
    MaterialInput,
    MaterialSwitch,
    MaterialButton,
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local"
    })

    // Something todo ..showLoader

    return { t }
  },
  props: {
    isFormSending: Boolean,
  },
  data() {
    return {
      username: "",
      password: "",
    }
  },
  computed: {
    ...mapGetters("AUTH",["showLoader"]),

  },
  methods: {
    redirectTo() {
      this.$router.push({name: "default"})
    },
    handleSubmit() {
      // this.$v.$touch()

      // if (!this.$v.$invalid) {
      this.$emit("onFormSubmit", {
        username: this.username,
        password: this.password,
      })
      // }
    },
  },
  validations: {
    username: {
      required,
      minLength: minLength(4),
    },
    password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(64),
    },
  },
}
</script>

<style scoped></style>
