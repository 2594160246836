import axiosApiInstance from "@/config/axios.config"

class HttpServiceClass {
	constructor({ axiosApiInstance }) {
		this.axiosApiInstance = axiosApiInstance
	}

	removeAuthorizationHeader(headers) {
		const headersToUpdate = headers || this.axiosApiInstance.defaults.headers
		delete headersToUpdate.common["Authorization"]
	}

	setAuthorizationHeader(token, headers) {
		const headersToUpdate = headers || this.axiosApiInstance.defaults.headers
		headersToUpdate.common["Authorization"] = token
	}
}

const HttpService = new HttpServiceClass({ axiosApiInstance })

export default HttpService
