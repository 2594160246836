export default $axios => resource => ({
	wfhProperiesFetchList() {
		return $axios.get(
			`${resource}/stayfacilities/currentloggedinowner`
		)
	},

	wfhProperiesFetchItem(id) {
		return $axios.get(
			`${resource}/wfadata/datacapture/properties/${id}`
		)
	},

	wfhAccomudationFetchItem(propertyId, accommodationId) {
		return $axios.get(
			`${resource}/wfadata/datacapture/properties/${propertyId}/accommodations/${accommodationId}`
		)
	},

	wfhSaveData(payload) {
		return $axios.post(
			`${resource}/wfadata`, payload
		)
	},

	updateAccomudaitonPhotos(accommodationId, items) {
		const formData = new FormData()
		items.forEach((file) => {formData.append("files", file)})
		formData.append("accommodationId", accommodationId)
		return $axios.post(`${resource}/photos/accommodation`, formData)
	},

	updatePropertyPhotos(propertyId, items) {
		const formData = new FormData()
		items.forEach((file) => {formData.append("files", file)})
		formData.append("stayFacilityId", propertyId)
		return $axios.post(`${resource}/photos/stayfacilities`, formData)
	},

	wfhAccomudationFetchPhotos(accommodationId) {
		return $axios.get(
			`${resource}/photos/accommodation/${accommodationId}`
		)
	},

	wfhAccomudationDeletePhotos(payload) {
		return $axios.delete(
			`${resource}/photos/accommodation`, {
				data: payload,
			}
		)
	},

	wfhPropertyFetchPhotos(stayFacilityId) {
		return $axios.get(
			`${resource}/photos/stayfacilities/${stayFacilityId}`
		)
	},

	wfhPropertyDeletePhotos(payload) {
		return $axios.delete(
			`${resource}/photos/stayfacilities`,  {
				data: payload,
			}
		)
	},

	fetchCurrencyList() {
		return $axios.get(
			`${resource}/static/supportedcurrencies`
		)
	}
})