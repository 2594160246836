<template>
  <button
    class="btn mb-0"
    :class="getClasses(variant, color, size, fullWidth, active)"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "MaterialFab",
  props: {
    color: {
      type: String,
      default: "primary",
    },
    variant: {
      type: String,
      default: "fill",
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getClasses: (variant, color, active) => {
      let colorValue,  activeValue

      // Setting the button variant and color
      if (variant === "gradient") {
        colorValue = `bg-gradient-${color}`
      } else if (variant === "outline") {
        colorValue = `btn-outline-${color}`
      } else {
        colorValue = `btn-${color}`
      }

      activeValue = active ? "active" : null

      return `${colorValue} ${activeValue}`
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  padding: 0;
  width: 36px;
  height: 36px;
  border-radius: 100px;
}
</style>