export default {
  "PAGES": {
    "LOGIN_PAGE": {
      "LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
      "REMEMBER_ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnere dich an mich"])},
      "ALREADY_HAVE_NOT_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben kein Konto?"])},
      "SIGNUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])}
    }
  },
  "MODALS": {
    
  },
  "VALIDATIONS": {
    
  }
}