export const WFH = "WFH"

export const WFH_PROPERTIES_FETCH_LIST =
  "WFH_PROPERTIES_FETCH_LIST"

export const WFH_PROPERTIES_FETCH_ITEM =
  "WFH_PROPERTIES_FETCH_ITEM"

export const WFH_ACCOMUDATION_FETCH_ITEM =
  "WFH_ACCOMUDATION_FETCH_ITEM"

export const WFH_SAVE_DATA =
  "WFH_SAVE_DATA"

export const WFH_SAVE_ACCOMUDATION_PHOTOS =
  "WFH_SAVE_ACCOMUDATION_PHOTOS"

export const WFH_SAVE_PROPERTY_PHOTOS =
  "WFH_SAVE_PROPERTY_PHOTOS"

export const WFH_FETCH_ACCOMUDATION_PHOTOS =
  "WFH_FETCH_ACCOMUDATION_PHOTOS"

export const WFH_FETCH_PROPERTY_PHOTOS =
  "WFH_FETCH_PROPERTY_PHOTOS"

export const WFH_DELETE_ACCOMUDATION_PHOTOS =
  "WFH_DELETE_ACCOMUDATION_PHOTOS"

export const WFH_DELETE_PROPERTY_PHOTOS =
  "WFH_DELETE_PROPERTY_PHOTOS"

export const FETCH_CURRENCY_LIST =
  "FETCH_CURRENCY_LIST"