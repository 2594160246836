import { get as _get } from "lodash"
import { createRouter, createWebHistory } from "vue-router"
import { AUTH } from "@/modules/auth/store/mutations-types"
// import Default from "../views/dashboards/Default.vue"
import WfhDashboard from "../modules/wfh/views/wfhDashboard.vue"
import WfhAccomodation from "../modules/wfh/views/wfhAccomodation.vue"
import WfhProperties from "../modules/wfh/views/wfhProperties.vue"
// import Sales from "../views/dashboards/Sales.vue"
// import Overview from "../views/pages/profile/Overview.vue"
// import Projects from "../views/pages/profile/Projects.vue"
// import Timeline from "../views/pages/projects/Timeline.vue"
// import Pricing from "../views/pages/Pricing.vue"
// import RTL from "../views/pages/Rtl.vue"
// import Charts from "../views/pages/Charts.vue"
// import Notifications from "../views/pages/Notifications.vue"
// import Kanban from "../views/applications/Kanban.vue"
// import Wizard from "../views/applications/wizard/Wizard.vue"
// import DataTables from "../views/applications/DataTables.vue"
// import Calendar from "../views/applications/Calendar.vue"
// import NewProduct from "../views/ecommerce/products/NewProduct.vue"
// import EditProduct from "../views/ecommerce/products/EditProduct.vue"
// import ProductPage from "../views/ecommerce/products/ProductPage.vue"
// import OrderDetails from "../views/ecommerce/orders/OrderDetails"
// import OrderList from "../views/ecommerce/orders/OrderList"
// import NewUser from "../views/pages/users/NewUser.vue"
// import Settings from "../views/pages/account/Settings.vue"
// import Billing from "../views/pages/account/Billing.vue"
// import Invoice from "../views/pages/account/Invoice.vue"
// import Widgets from "../views/pages/Widgets.vue"
// import Basic from "../views/auth/signin/Basic.vue"
// import Cover from "../views/auth/signin/Cover.vue"
// import Illustration from "../views/auth/signin/Illustration.vue"
// import ResetCover from "../views/auth/reset/Cover.vue"
// import SignupCover from "../views/auth/signup/Cover.vue"
import LoginPage from "../modules/auth/views/pages/LoginPage.vue"
import GuestLayout from "../views/layouts/GuestLayout"

const routes = [
  {
    path: "",
    name: "/",
    component: GuestLayout,
    redirect: "/dashboard",
    meta: {
      public: true,
    },
  },
  {
    path: "/login",
    name: "Signin",
    component: LoginPage,
    meta: {
      public: true,
    },
  },
  {
    path: "/dashboard",
    name: "Open-air Work From Anywhere Properties",
    component: WfhDashboard,
    meta: {
      public: false,
    },
  },
  {
    path: "/dashboard/properties/:pId/accommodations/:accId",
    name: "WFH Accomodation Details",
    component: WfhAccomodation,
    meta: {
      public: false,
    },
  },
  {
    path: "/dashboard/properties/:id",
    name: "OWFA Property Details",
    component: WfhProperties,
    meta: {
      public: false,
    },
  },
  // {
  // 	path: "/dashboards/dashboard-default",
  // 	name: "Default",
  // 	component: Default,
  // },
  // {
  // 	path: "/dashboards/sales",
  // 	name: "Sales",
  // 	component: Sales,
  // },
  // {
  // 	path: "/pages/profile/overview",
  // 	name: "Profile Overview",
  // 	component: Overview,
  // },
  // {
  // 	path: "/pages/profile/projects",
  // 	name: "All Projects",
  // 	component: Projects,
  // },
  // {
  // 	path: "/pages/projects/timeline",
  // 	name: "Timeline",
  // 	component: Timeline,
  // },
  // {
  // 	path: "/pages/pricing-page",
  // 	name: "Pricing Page",
  // 	component: Pricing,
  // },
  // {
  // 	path: "/pages/rtl-page",
  // 	name: "RTL",
  // 	component: RTL,
  // },
  // {
  // 	path: "/pages/charts",
  // 	name: "Charts",
  // 	component: Charts,
  // },
  // {
  // 	path: "/pages/widgets",
  // 	name: "Widgets",
  // 	component: Widgets,
  // },
  // {
  // 	path: "/pages/notifications",
  // 	name: "Notifications",
  // 	component: Notifications,
  // },
  // {
  // 	path: "/applications/kanban",
  // 	name: "Kanban",
  // 	component: Kanban,
  // },
  // {
  // 	path: "/applications/wizard",
  // 	name: "Wizard",
  // 	component: Wizard,
  // },
  // {
  // 	path: "/applications/data-tables",
  // 	name: "Data Tables",
  // 	component: DataTables,
  // },
  // {
  // 	path: "/applications/calendar",
  // 	name: "Calendar",
  // 	component: Calendar,
  // },
  // {
  // 	path: "/ecommerce/products/new-product",
  // 	name: "New Product",
  // 	component: NewProduct,
  // },
  // {
  // 	path: "/ecommerce/products/edit-product",
  // 	name: "Edit Product",
  // 	component: EditProduct,
  // },
  // {
  // 	path: "/ecommerce/products/product-page",
  // 	name: "Product Page",
  // 	component: ProductPage,
  // },
  // {
  // 	path: "/ecommerce/Orders/order-details",
  // 	name: "Order Details",
  // 	component: OrderDetails,
  // },
  // {
  // 	path: "/ecommerce/Orders/order-list",
  // 	name: "Order List",
  // 	component: OrderList,
  // },
  // {
  // 	path: "/pages/users/new-user",
  // 	name: "New User",
  // 	component: NewUser,
  // },
  // {
  // 	path: "/pages/account/settings",
  // 	name: "Settings",
  // 	component: Settings,
  // },
  // {
  // 	path: "/pages/account/billing",
  // 	name: "Billing",
  // 	component: Billing,
  // },
  // {
  // 	path: "/pages/account/invoice",
  // 	name: "Invoice",
  // 	component: Invoice,
  // },
  // {
  // 	path: "/authentication/signin/basic",
  // 	name: "Signin Basic",
  // 	component: Basic,
  // },
  // {
  // 	path: "/authentication/signin/cover",
  // 	name: "Signin Cover",
  // 	component: Cover,
  // },
  // {
  // 	path: "/authentication/signin/illustration",
  // 	name: "Signin Illustration",
  // 	component: Illustration,
  // },
  // {
  // 	path: "/authentication/reset/cover",
  // 	name: "Reset Cover",
  // 	component: ResetCover,
  // },
  // {
  // 	path: "/authentication/signup/cover",
  // 	name: "Signup Cover",
  // 	component: SignupCover,
  // },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    let newScroll = { x: 0, y: 0 }

    if (to.hash) {
      newScroll = {
        selector: to.hash,
      }
    } else if (savedPosition) {
      newScroll = savedPosition
    }

    return newScroll
  },
})

export const routerHooksSetup = $store => {
  router.beforeEach((to, from, next) => {

    const isPublic = to.matched.some(record => record.meta.public)

    const isOnlyWhenLoggedOut = to.matched.some(
      record => record.meta.onlyWhenLoggedOut
    )
    const loggedIn = _get($store, `getters["${AUTH}/isAuthenticated"]`)
    if (!isPublic && !loggedIn) {
      return next({
        path: "/login",
        query: { redirect: to.fullPath },
      })
    } else if (loggedIn && isOnlyWhenLoggedOut) {
      return next("/home")
    } else {
      next()
    }
  })
}

export default router
