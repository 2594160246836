<template>
    <div class="step-3-fix-height mt-4">
          <h3 class="text-align-left">{{ title }}</h3>
          <h5 class="text-align-left">{{ subheadings[0] }}</h5>
          <div class="mt-2 mb-2">
        
            <div v-for="wfa_service in step3Data.wfa_service"  :key="wfa_service.itemName">
              <div class="form-check p-0">
                  <input
                    :id="id"
                    class="form-check-input"
                    type="checkbox"
                    :name="wfa_service.itemName"
                    value="true"
                    v-model="wfa_service.itemValue"

                  />
                  <label class="custom-control-label color-black">
                    {{ wfa_service.itemName }}
                  </label>
                </div>
            </div>
          </div>
          <h5 class="text-align-left">
            {{ subheadings[1] }}
          </h5>
          <div class="step-3-text-editor mt-2 mb-5">
            <QuillEditor
              style="min-height: 150px"
              theme="snow"
              placeholder="Some initial bold text"
              v-model:content="services_detail"
              contentType="html"
            />
          </div>
          <h5 class="text-align-left">{{ subheadings[2] }}</h5>
          <div class="mt-2 mb-5">

            <div v-for="wfa_service_1_5 in step3Data.wfa_service_1_5"  :key="wfa_service_1_5.itemName">
              <div class="form-check p-0">
                  <input
                    :id="id"
                    class="form-check-input"
                    type="checkbox"
                    :name="wfa_service_1_5.itemName"
                    value="true"
                    v-model="wfa_service_1_5.itemValue"

                  />
                  <label class="custom-control-label color-black">
                    {{ wfa_service_1_5.itemName }}
                  </label>
                </div>
            </div>
          </div>
          <h5 class="text-align-left">{{ subheadings[3] }}</h5>
          <div class="mt-2 pb-3">
            <div v-for="wfa_close_by_1_5 in step3Data.wfa_close_by_1_5"  :key="wfa_close_by_1_5.itemName">
              <div class="form-check p-0">
                  <input
                    :id="id"
                    class="form-check-input"
                    type="checkbox"
                    :name="wfa_close_by_1_5.itemName"
                    value="true"
                    v-model="wfa_close_by_1_5.itemValue"

                  />
                  <label class="custom-control-label color-black">
                    {{ wfa_close_by_1_5.itemName }}
                  </label>
                </div>
            </div>
          </div>
        </div>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill"
import "@vueup/vue-quill/dist/vue-quill.snow.css"
export default {
	name: "CustomStep3",
	components: {
		QuillEditor
	},
	props: {
		propertyStep3: {
			type: Object,
		},
	},
	data() {
		return {
			title: "",
			subheadings: [],
			services_detail: "",
			step3Data: ""
		}
	},
	created() {
		this.step3Data = this.propertyStep3
		this.title = this.propertyStep3.title
		this.subheadings = this.propertyStep3.subheadings
		this.services_detail = this.propertyStep3.wfa_service_additional_detail

	},
	methods: {
		getPayload() {
			let payload = [
				{
					"itemName": "wfa_service_on_site",
					"itemValue": "In the campsite",
					"stepDataCaptured": 3,
					"itemAdditionalInfo": this.step3Data.wfa_service
				},
				{
					"itemName": "wfa_service_additional_detail",
					"itemValue": this.services_detail,
					"stepDataCaptured": 3
				},
				{
					"itemName": "wfa_service_1_5",
					"stepDataCaptured": 3,
					"itemAdditionalInfo": this.step3Data.wfa_service_1_5
				},
				{
					"itemName": "wfa_close_by_1_5",
					"stepDataCaptured": 3,
					"itemAdditionalInfo": this.step3Data.wfa_close_by_1_5
				}
			]
			return payload
		}
	},
}
</script>

<style src="../../../../assets/css/Stepper.css" scoped>

</style>