export default {
  "PAGES": {
    "LOGIN_PAGE": {
      "LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden"])},
      "REMEMBER_ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onthoud mij"])},
      "ALREADY_HAVE_NOT_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heeft u geen account?"])},
      "SIGNUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden"])}
    }
  },
  "MODALS": {
    
  },
  "VALIDATIONS": {
    
  }
}