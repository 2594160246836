<template>
  <div>
    <div class="row">
      <div class="col mt-5 mb-5">
        <h5>
          Property <br />
          opening dates
        </h5>
      </div>
      <div class="col-5 mt-5 mb-5">
        <div class="card">
          <div class="d-flex">
            <div
              class="icon icon-shape icon-lg bg-success shadow text-center border-radius-xl mt-n3 mx-4"
            >
              <i class="material-icons opacity-10" aria-hidden="true">
                event
              </i>
            </div>
            <span class="text-align-left">Remote worker Open date</span>
          </div>
          <div class="m-4">
            <material-input
              id="openingDate"
              type="date"
              label=""
              name="openingDate"
              v-model="wfa_property_open_from_date"
              :isRequired="true"
            />
          </div>
        </div>
      </div>
      <div class="col-5 mt-5 mb-5">
        <div class="card">
          <div class="d-flex">
            <div
              class="icon icon-shape icon-lg bg-success shadow text-center border-radius-xl mt-n3 mx-4"
            >
              <i class="material-icons opacity-10" aria-hidden="true">
                event
              </i>
            </div>
            <span class="text-align-left">Remote worker Close date</span>
          </div>
          <div class="m-4">
            <material-input
              id="closingDate"
              type="date"
              label=""
              name="closingDate"
              v-model="wfa_property_open_to_date"
              :isRequired="true"
            />
          </div>
        </div>
      </div>
    </div>
    <div>
      <h5>
        Opening and closing dates of any amenties and facilities that differ
        from campsite opening and closing dates
      </h5>
    </div>

    <div class="row">
      <div class="col-4">
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <button
              v-for="(item, index) in wfa_amenity"
              :key="item.id"
              class="nav-link"
              :class="{ active: index == 0 }"
              :id="item.key + 'nav'"
              data-bs-toggle="tab"
              :data-bs-target="'#nav-' + item.key"
              type="button"
              role="tab"
              :aria-controls="'#nav-' + item.key"
              aria-selected="true"
            >
              {{ item.name }}
            </button>
          </div>
        </nav>
      </div>

      <div class="col-8">
        <div class="tab-content" id="nav-tabContent">
          <div
            v-for="(item, index) in wfa_amenity"
            :key="item.id"
            class="tab-pane fade show"
            :class="{ active: index == 0 }"
            :id="'nav-' + item.key"
            role="tabpanel"
            :aria-labelledby="'nav-' + item.key + '-tab'"
          >
            <div class="d-flex align-items-center">
              <div class="form-check">
                <input
                  :id="id"
                  class="form-check-input"
                  type="radio"
                  :name="`time${index}`"
                  value="true"
                  v-model="wfa_amenity[index].time.itemValue"
                />
                <label class="custom-control-label color-black">
                  Open all year round
                </label>
              </div>
              <div class="form-check">
                <input
                  :id="id"
                  class="form-check-input"
                  type="radio"
                  :name="`time${index}`"
                  value="false"
                  v-model="wfa_amenity[index].time.itemValue"
                />
                <label class="custom-control-label color-black">
                  Set time
                </label>
              </div>
              <h5 v-if="index === 0" class="ms-auto">Resturant Times</h5>
            </div>
            <div v-if="wfa_amenity[index].time.itemValue === 'false'">
              <div v-for="(date, dateIndex) in item.dates" :key="date.id">
                <div class="row mt-3">
                  <div class="col-5">
                    <material-input
                      id="openingDate"
                      type="date"
                      name="openingDate"
                      v-model="wfa_amenity[index].dates[dateIndex].open"
                      :isRequired="true"
                    />
                  </div>

                  <div class="col-5">
                    <material-input
                      id="openingDate"
                      type="date"
                      label=""
                      name="openingDate"
                      v-model="wfa_amenity[index].dates[dateIndex].close"
                      :isRequired="true"
                    />
                  </div>

                  <div class="col-2">
                    <MaterialFab v-if="dateIndex == item.dates.length - 1"
                      @click="addAmenityDate(index)">
                      <i class="material-icons opacity-10"> add </i>
                    </MaterialFab>
                    <MaterialFab color="danger" v-else
                      @click="removeAmenityDate(index, dateIndex)">
                      <i class="material-icons opacity-10"> remove </i>
                    </MaterialFab>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue"
import MaterialFab from "@/components/MaterialFab.vue"

import { getItemCollection, getItemsCollection } from "@/utils/api.utils"

export default {
  name: "CustomStep2",
  components: {
    MaterialInput,
    MaterialFab,
  },
  props: {
    propertyStep2: {
      type: Object,
    },
  },
  data() {
    return {
      title: "",
      description: "",
      openingDate: "",
      closingDate: "",
      wfa_amenity: [],

      wfa_property_open_from_date: "",
      wfa_property_open_to_date: "",
    }
  },
  created() {
    ;(this.title = this.propertyStep2.title),
    (this.description = this.propertyStep2.description)

    this.wfa_property_open_from_date =
      this.propertyStep2.wfa_property_open_from_date
    this.wfa_property_open_to_date =
      this.propertyStep2.wfa_property_open_to_date

    let wfa_amenity_data = this.propertyStep2.wfa_amenity
    wfa_amenity_data.forEach(element => {
      let tempData = []
      tempData["name"] = element.itemName
      tempData["key"] = element.itemName.replace(" ", "-")
      tempData["time"] = getItemCollection(
        element.itemAdditionalInfo,
        "open_all_year"
      )

      let open = getItemsCollection(
        element.itemAdditionalInfo,
        "set_dates_opening_date"
      )
      let close = getItemsCollection(
        element.itemAdditionalInfo,
        "set_dates_closing_date"
      )
      let date = []

      for (let item = 0; item < open.length; item++) {
        let temp_date = []
        temp_date["open"] = open[item]?.itemValue
        temp_date["close"] = close[item]?.itemValue
        date.push(temp_date)
      }

      if (date.length == 0) {
        date = [{ start: "", end: "" }]
      }
      tempData["dates"] = date
      this.wfa_amenity.push(tempData)
    })
    this.getAmenitiesPayload()
  },
  methods: {
    addAmenityDate(index) {
      this.wfa_amenity[index].dates.push({
        start: "02-02-2023",
        end: "02-02-2023",
      })
    },
    removeAmenityDate(index, dateIndex) {
      this.wfa_amenity[index].dates.splice(dateIndex, 1)
    },
    getAmenitiesPayload() {
      let amenitiesData = []
      if (this.wfa_amenity.length) {
        let obj
        let entry
        this.wfa_amenity.forEach(element => {
          obj = {
            itemName: element.name,
            itemAdditionalInfo: [],
          }
          entry = {
            itemName: "open_all_year",
            itemValue: element.time.itemValue,
          }
          obj["itemAdditionalInfo"].push(entry)

          if (element.dates.length) {
            element.dates.forEach(date => {
              if (date.open) {
                entry = {
                  itemName: "set_dates_opening_date",
                  itemValue: date.open,
                }
                obj["itemAdditionalInfo"].push(entry)
              }

              if (date.close) {
                entry = {
                  itemName: "set_dates_closing_date",
                  itemValue: date.close,
                }
                obj["itemAdditionalInfo"].push(entry)
              }
            })
          }
          amenitiesData.push(obj)
        })
      }
      return amenitiesData
    },
    getPayload() {
      let payload = [
        {
          itemName: "wfa_property_open_from_date",
          itemValue: this.wfa_property_open_from_date,
          stepDataCaptured: 2,
        },
        {
          itemName: "wfa_property_open_to_date",
          itemValue: this.wfa_property_open_to_date,
          stepDataCaptured: 2,
        },
        {
          itemName: "wfa_amenities",
          stepDataCaptured: 2,
          itemAdditionalInfo: this.getAmenitiesPayload(),
        },
      ]
      return payload
    },
  },
}
</script>
<style scoped>
.plus-icon {
  color: black;
  font-size: 46px;
}
.nav-tabs {
  flex-direction: column;
}
.nav-link {
  color: gray;
  text-align: left;
}
.nav-link.active {
  color: black !important;
  border: none !important;
}
.nav-link:hover {
  color: green !important;
}
</style>
