<template>
    <div class="row">
        <div class="col-6 mt-5 mb-5">
          <div>
            <h4 class="text-align-left">{{ step1.title }}</h4>
          </div>
          <div>
            <form role="form" class="text-start mt-3">
              <div class="mb-4 mt-4">
                <material-input
                  id="id"
                  type="text"
                  :label="idField"
                  :name="idField"
                  v-model="id"
                  :isRequired="true"
                  disabled="true"
                />
              </div>
              <div class="mb-4">
                <material-input
                  id="name"
                  type="text"
                  :label="nameField"
                  :name="nameField"
                  v-model="name"
                  :isRequired="true"
                  disabled="true"
                />
              </div>
              <div class="mb-4" v-if="step1.extraField">
          <label class="form-label mt-4 ms-0">{{ step1.extraField }}</label>
          <input
            id="choices-extras"
            class="form-control"
            type="text"
            :value="wfa_accommodation_feature.toString()"
            placeholder="Enter something"
            onfocus="focused(this)"
            onfocusout="defocused(this)"
          >
        </div>
            </form>
          </div>
        </div>
        <div class="col-6">
          <form class="text-start mt-7 mb-7">
            <div class="m-auto">
              <label class="form-label">{{ descriptionField }}</label>
              <QuillEditor
                style="min-height: 150px"
                theme="snow"
                v-model:content="description"
                contentType="html"
              />
            </div>
          </form>
        </div>
    </div>
</template>

<script>
import MaterialInput from "../../../../components/MaterialInput.vue"
import { QuillEditor } from "@vueup/vue-quill"
import "@vueup/vue-quill/dist/vue-quill.snow.css"
import * as Choices from "choices.js"


export default {
	name: "CustomStep1",
	components: {
		MaterialInput,
		QuillEditor
	},
	props: {
		step1: {
			type: Object,
		},
		type: {
			type: String,
		},
	},
	data() {
		return {
			id: "",
			idField: "",
			name: "",
			nameField: "",
			description: "",
			descriptionField: "",
			wfa_accommodation_feature:[]
		}
	},
	created() {
		this.id = this.step1.id
		this.idField = this.step1.idField
		this.name = this.step1.name
		this.nameField = this.step1.nameField
		this.description = this.step1.description
		this.descriptionField = this.step1.descriptionField


		this.wfa_accommodation_feature = this.step1.wfa_accommodation_feature
		if(this.wfa_accommodation_feature ) {
			this.wfa_accommodation_feature = this.wfa_accommodation_feature.replace("[", "")
			this.wfa_accommodation_feature = this.wfa_accommodation_feature.replace("]", "")
		}
   

	},
	mounted() {
		if (document.getElementById("choices-extras")) {
			var skills = document.getElementById("choices-extras")
			new Choices(skills, {
				delimiter: ",",
				editItems: true,
				maxItemCount: 5,
				removeItemButton: true,
				addItems: true,
			})
		}
	},
	methods: {
		getPayload() {
			let payload = ""
			if(this.type) {
				payload =  {
					"itemName": "wfa_property_description",
					"itemValue": this.description,
					"stepDataCaptured": 1
				}
			} else {
				var skills = document.getElementById("choices-extras")
				let extras = `[${skills.value}]`
				payload =  [
					{
						"itemName": "wfa_accommodation_description",
						"itemValue": this.description,
						"stepDataCaptured": 1
					},
					{
						"itemName": "wfa_accommodation_feature",
						"itemValue": extras,
						"stepDataCaptured": 1
					},
				]
			}

			return payload
		}
	}
  
}
</script>
<style src="../../../../assets/css/Stepper.css" scoped>
</style>