<template>
  <div
    class="pt-3 bg-white multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
 
    <div class="mt-3 multisteps-form__content">
      <div class="row" v-if="currencyDropdown.length">
          <div class="col-3">
            <h5 class="font-weight-bolder">Preferred Currency</h5>
            </div>
            <div class="col-4">
          <select
            id="choices-currency"
            class="form-control"
            name="choices-sizes"
            v-model="preferred_currency"
          >
			<option v-for=" currency in currencyDropdown" :key="currency"  :value="currency">{{currency}}</option>
          </select>
        </div>
      </div>
    </div>

    <h5 class="font-weight-bolder">Price Per Week</h5>
    <div class="mt-3 multisteps-form__content">
      <div class="row">
        <div class="col-6">
          <material-input id="price" v-model="price_per_week"  variant="dynamic" />
        </div>
       
      </div>
    </div>
    <h5 class="font-weight-bolder">Price Per Month</h5>
    <div class="mt-3 multisteps-form__content">
      <div class="row">
        <div class="col-6">
          <material-input id="price" v-model="price_per_month" variant="dynamic" />
        </div>
      </div>
    </div>
    <div class="d-flex mt-4">

    <div class="form-check p-0">
      <label class="custom-control-label color-black">
                    Apply these prices to all listed accomodation types&nbsp;&nbsp; 
                  </label>
                  <input
                    :id="id"
                    class="form-check-input"
                    type="checkbox"
                    value="true"
                    v-model="apply_all"

                  />
                  
                </div>
    </div>
  </div>
</template>

<script>
import Choices from "choices.js"
import MaterialInput from "@/components/MaterialInput.vue"
import { getItemCollection } from "@/utils/api.utils"
import { WFH, FETCH_CURRENCY_LIST } from "../../store/mutations-type"
import { mapActions, mapState } from "vuex"

export default {
	name: "PricingPage",
	components: {
		MaterialInput,
	},
	props: {
		accomudationStep2: {
			type: Object,
		},
	},
	data() {
		return {
			price_per_week: "",
			price_per_month: "",
			preferred_currency: "",
			apply_all: "",
			currencyDropdown: [],
		}
	},

	computed: {
		...mapState(WFH, {
			currencyList: state => state.currencyList,
		}),
	},

	async created() {
		await this.fetchCurrencyList()
		this.currencyDropdown = this.currencyList.data

		this.price_per_week = await getItemCollection(this.accomudationStep2.wfa_accommodation_price, "price_per_week").itemValue
		this.price_per_month = await getItemCollection(this.accomudationStep2.wfa_accommodation_price, "price_per_month").itemValue
		this.preferred_currency = await getItemCollection(this.accomudationStep2.wfa_accommodation_price, "preferred_currency").itemValue
		this.apply_all = await getItemCollection(this.accomudationStep2.wfa_accommodation_price, "apply_all").itemValue

		if (document.getElementById("choices-currency")) {
			let element = document.getElementById("choices-currency")
			new Choices(element, {
				searchEnabled: false,
			})
		}

	},

	methods: {
		...mapActions(WFH, {
			fetchCurrencyList: FETCH_CURRENCY_LIST,
		}),

		getPayload() {
			return [
				{
					"itemName": "wfa_accommodation_price",
					"stepDataCaptured": 2,
					"itemAdditionalInfo": [
						{
							"itemName": "price_per_week",
							"itemValue": this.price_per_week
						},
						{
							"itemName": "price_per_month",
							"itemValue": this.price_per_month
						},
						{
							"itemName": "preferred_currency",
							"itemValue": this.preferred_currency
						},
						{
							"itemName": "apply_all",
							"itemValue": this.apply_all
						}
					]
				}
			]
		}

	}
}
</script>
