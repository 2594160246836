<template>
  <div class="py-4 container-fluid min-height">
    <div class="row">
      <div class="col-lg-12 position-relative z-index-2">
        <CustomStartupModal
          v-if="isShowDisclaimer"
          :data="startupData"
          :onClose="onCloseStartupModal"
        />
        <CustomStartupModal
          :data="welcomeData"
          :onClose="onCloseWelcomeModal"
        />

        <UniversalTable
          v-if="showProperty"
          title="List of Open-air Work From Anywhere Properties"
          :data="wfhPropertiesList"
          @onRowSelect="onPropertyRowSelect"
          @onEditClick="onPropertyRowClick"
        >
        </UniversalTable>
        
        <UniversalTable
          v-if="showAccomodations"
          title="List of Accomodations"
          :data="wfhAccomodationData"
          :key="key"
          @onEditClick="onAccomodationRowClick"
        />
      </div>
    </div>

    <div class="row"></div>
  </div>
</template>
<script>
import UniversalTable from "../../../components/table/UniversalTable.vue"
import CustomStartupModal from "./components/CustomStartupModal.vue"
import { WFH, WFH_PROPERTIES_FETCH_LIST } from "../store/mutations-type"
import { getItemCollection } from "@/utils/api.utils"
import { mapActions, mapState } from "vuex"

export default {
  name: "wfhDashboard",
  components: {
    CustomStartupModal,
    UniversalTable,
  },
  computed: {
    ...mapState(WFH, {
      wfhPropertiesList: state => state.wfhPropertiesList,
    }),
  },
  mounted() {
    if (sessionStorage.getItem("showDisclaimer") == null) {
      sessionStorage.setItem("showDisclaimer", true)
      this.isShowDisclaimer = true
    }
  },
  data() {
    return {
      showProperty: false,
      showAccomodations: false,
      isShowDisclaimer: false,
      isProperty: false,
      isWizard: false,
      startupData: {},
      welcomeData: {},
      wfhPropertyData: [],
      wfhAccomodationData: [],
      selectedPropertyId: "",
      key: 0,
    }
  },
  async created() {
    await this.initData()
    await this.wfhProperiesFetchList()
    this.showProperty = true
  },
  methods: {
    ...mapActions(WFH, {
      wfhProperiesFetchList: WFH_PROPERTIES_FETCH_LIST,
    }),
    initData() {
      this.startupData = {
        isShow: true,
        header: "CONDITIONS OF THE SERVICE",
        isdefaultImage: true,
        isdefaultButton: true,
        buttonText: "OK",
        bodyType: "ul",
        body: [
          "- Campsites must off e or 3 bedroom accomodation for 1 to 4 people.",
          "- Beds will be removed from one of the bedrooms and replaced by a desk and chair.",
          "- Rates(freely fixed by campsites) will be offered for stays from 7 days to 3 months.",
          "- Arrivals on any day of the week.",
          "- The offer will be valid in low season only (outside of July and August).",
          "- 15% commission",
        ],
        extraText: "",
      }
      this.welcomeData = {
        isShow: false,
        header: "WELCOME",
        isdefaultImage: true,
        isdefaultButton: true,
        buttonText: "Start",
        bodyType: "text",
        body: "We are delighted to have you on board, to offer remote workers the joy of living in the open-air for 1 - 12 weeks! We need additional informations about your campsite, to allow us to offer it as an option to remote workers.",
        extraText: "Thank you for your time !",
      }
    },

    onCloseStartupModal() {
      this.startupData.isShow = false
      this.initWelcomeModal()
    },

    initWelcomeModal() {
      this.welcomeData.isShow = true
    },

    onCloseWelcomeModal() {
      this.welcomeData.isShow = false
    },
    onPropertyRowClick(row) {
      this.selectedProperty = getItemCollection(row.row.row, "property_id")
      this.$router.push(`/dashboard/properties/${this.selectedProperty.value}`)
    },
    onAccomodationRowClick(row) {
      let item = getItemCollection(row.row.row, "accommodation_id")

      this.$router.push(
        `/dashboard/properties/${this.selectedPropertyId}/accommodations/${item.value}`
      )
    },
    onCloseProperty() {
      this.isProperty = false
    },
    onPropertyRowSelect(row) {
      this.selectedPropertyId = row.property_id
      this.showAccomodations = true
      let element = getItemCollection(row.row.row, "accommodations")
      this.wfhAccomodationData = element.embeddedGrid
      this.key++
    },
  },
}
</script>
<style scoped>
.min-height {
  min-height: 85vh !important;
}
</style>
