<template>
  <div class="modal-mask" v-if="data.isShow">
    <div class="modal-wrapper bg-body shadow-lg p-4 ">
      <div class="modal-container">
        <div class="modal-header border-0">
          <img
            class="min-height-100 max-height-100 border-radius-lg"
            src="../../../../assets/img/logos/logo-campsite.svg"
            alt="Campsite logo"
          />
        </div>

        <div class="modal-body pt-5 body-font" v-if="data.bodyType === 'ul'">
          <slot name="header">
            {{ data.header }}
          </slot>
          <slot name="body">
            <ul class="list-unstyled">
              <li class="body-font" v-for="entry in data.body" :key="entry">
                {{ entry }}
              </li>
            </ul>
          </slot>
        </div>
        <div class="modal-body pt-5 body-font" v-if="data.bodyType === 'text'">
          <slot name="header">
            {{ data.header }}
          </slot>
          <slot name="body">
            <div>
              {{ data.body }}
            </div>
          </slot>
          <div class="pt-4 pb-4" v-if="data.extraText">
            {{ data.extraText }}
          </div>
        </div>

        <div class="modal-footer border-0">
          <slot name="footer">
            <button
              class="btn mb-0 my-4 mb-2 btn-success"
              @click="onClose"
              v-if="data.isdefaultButton"
            >
            <span class="px-2">
              {{ data.buttonText }}
            </span>
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CustomStartupModal",
  data() {},
  props: {
    data: {
      type: Object,
    },
    onClose: {
      type: Function,
    },
  },
}
</script>
<style lang="scss" scoped>
.modal-mask {
  z-index: 10;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.8); ;
  height: 100%;
  width: 100%;
  top: 0;
  
}

.modal-wrapper {
  width: 800px;
  border-radius: 10px;
  margin: auto;
  position: absolute;
  top: 40%;
  left: 40%;
  transform: translate(-50%, -50%);
}
</style>
