<template>
  <div class="container-fluid py-4">
    <div class="card">
      <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
        <div
          class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
        >
          <div class="multisteps-form__progress">
            <button
              v-for="(item, index) in stepperOptions"
              :key="index"
              class="multisteps-form__progress-btn"
              type="button"
              title="User Info"
              :class="activeStep >= index ? activeClass : ''"
              @click="activeStep = index"
            >
              <span>{{ item.title }}</span>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form class="multisteps-form__form">
          <div v-if="isProperty">
            <!-- STEP => # 01 -->
            <div
              :class="activeStep === 0 ? activeClass : ''"
              v-if="activeStep === 0"
              class="mt-6 mb-6"
            >
              <CustomStep1
                ref="propertyStep1"
                :type="isProperty"
                :step1="propertiesData.step1"
              />
            </div>

            <!-- STEP => # 02 -->
            <div
              :class="activeStep === 1 ? activeClass : ''"
              v-if="activeStep === 1"
            >
              <CustomStep2
                ref="propertyStep2"
                :propertyStep2="propertiesData.step2"
              />
            </div>

            <!-- STEP => # 03 -->
            <div
              :class="activeStep === 2 ? activeClass : ''"
              class="step-3-scroll"
              v-if="activeStep === 2"
            >
              <CustomStep3
                ref="propertyStep3"
                :propertyStep3="propertiesData.step3"
              />
            </div>

            <!-- STEP => # 04 -->
            <div
              :class="activeStep === 3 ? activeClass : ''"
              v-if="activeStep === 3"
            >
              <div class="mt-5 mb-5">
                <h4 class="text-align-left">Property Photos</h4>
                <CustomMedia
                  :class="activeStep === 3 ? activeClass : ''"
                  ref="uploadMediaProperty"
                  :isProperty="true"
                  :step4="propertiesData.step4"
                  :id="propertiesData.step1.id"
                />
              </div>
            </div>
          </div>

          <div v-else>
            <!-- STEP => # 01 -->
            <div
              :class="activeStep === 0 ? activeClass : ''"
              v-if="activeStep === 0"
              class="mt-6 mb-6"
            >
              <CustomStep1
                ref="accommodationStep1"
                :type="isProperty"
                :step1="accomodationData.step1"
              />
            </div>

            <!-- STEP => # 02 -->
            <div
              :class="activeStep === 1 ? activeClass : ''"
              v-if="activeStep === 1"
            >
              <!-- <CustomStep2 :propertyStep2 = "accomodationData.step2" /> -->
              <CustomPricing
                ref="accommodationStep2"
                :accomudationStep2="accomodationData.step2"
                :class="activeStep === 1 ? activeClass : ''"
              />
            </div>

            <!-- STEP => # 03 -->
            <div
              :class="activeStep === 2 ? activeClass : ''"
              v-if="activeStep === 2"
            >
              <div class="mt-5 mb-5">
                <h4 class="text-align-left">Accomodation Photos</h4>
                <CustomMedia
                  ref="uploadMedia"
                  :step4="accomodationData.step4"
                  :id="accomodationData.step1.id"
                  :isProperty="false"
                  :class="activeStep === 2 ? activeClass : ''"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="button-row d-flex m-4">
        <material-button
          v-if="activeStep > 0"
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="prevStep"
        >
          Prev
        </material-button>
        <material-button
          v-if="activeStep < stepperOptions.length - 1"
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto js-btn-next"
          @click="nextStep"
        >
          Next
        </material-button>
        <material-button
          v-if="activeStep + 1 === stepperOptions.length"
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto js-btn-next"
          @click="doneStep"
        >
          Done
        </material-button>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue"
import CustomStep1 from "./CustomStep1.vue"
import CustomStep2 from "./CustomStep2.vue"
import CustomPricing from "./CustomPricing.vue"
import CustomStep3 from "./CustomStep3.vue"
import CustomMedia from "./CustomMedia.vue"
import {
  WFH,
  WFH_SAVE_DATA,
  WFH_SAVE_ACCOMUDATION_PHOTOS,
  WFH_SAVE_PROPERTY_PHOTOS,
} from "../../store/mutations-type"
import { mapState, mapActions } from "vuex"
import { getItemCollection } from "@/utils/api.utils"

export default {
  name: "CustomStepper",
  components: {
    MaterialButton,
    CustomStep1,
    CustomStep2,
    CustomPricing,
    CustomStep3,
    CustomMedia,
  },
  props: {
    isProperty: {
      default: false,
    },
    stepperOptions: {
      type: Array,
    },
    onClose: {
      type: Function,
    },
  },
  data() {
    return {
      showMenu: false,
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 3,
      currentPosition: 0,
      transitionType: "slide",
      propertiesData: {
        step1: {
          title: "Open-air Work From Anywhere Properties Description",
          id: "",
          name: "",
          descriptionField: "Description",
          description: "",
        },
        step2: {
          title: "Property opening dates",
          openingDate: "",
          closingDate: "",
          description:
            "Opening and closing dates of any amenties and facilities that differ from campsite opening and closing dates",

          wfa_property_open_from_date: "",
          wfa_property_open_to_date: "",
          wfa_amenity: [],
          wfa_amenity_set_dates_opening_date: [],
          wfa_amenity_set_dates_closing_date: [],
        },
        step3: {
          title: "WFA Services",
          wfa_service: [],
          wfa_service_additional_detail: "",
          wfa_service_1_5: [],
          wfa_close_by_1_5: [],
          subheadings: [
            "In the campsite",
            "Add any other relevant amenties or services",
            "Within walking distance (1km-5km)",
            "Close By",
          ],
        },
        step4: {
          media: [],
        },
      },
      propertiesPayload: {
        id: "",
        lang: "EN",
        wfaDataCaptureLevel: "PROPERTY",
        wfaDataCaptureItems: [],
      },
      accommodationsPayload: {
        id: "",
        lang: "EN",
        wfaDataCaptureLevel: "ACCOMMODATION",
        wfaDataCaptureItems: [],
      },
      accomodationData: {
        step1: {
          title: "Accomodation Description",
          idField: "",
          id: "",
          nameField: "",
          name: "",
          descriptionField: "Description",
          description: "",
          extraField: "Tell us about anything extras you have",
          wfa_accommodation_feature: [],
        },
        step2: {
          title: "Pricing",
          openingDate: "",
          closingDate: "",
          description:
            "Opening and closing dates of any amenties and facilities that differ from campsite opening and closing dates",
          wfa_accommodation_price: [],
        },
        step3: {
          title: "WFA Services",
          subheadings: [
            "In the campsite",
            "Add any other relevant amenties or services",
            "Within walking distance (1km-5km)",
            "Close by",
          ],
        },
        step4: {
          media: [],
        },
      },
      prevText: "Previous",
      nextText: "Next",
      doneText: "Done",
    }
  },
  computed: {
    ...mapState(WFH, {
      wfhPropertiesItem: state => state.wfhPropertiesItem,
      wfhAccomudationsItem: state => state.wfhAccomudationsItem,
      wfhAccomudationsMedia: state => state.wfhAccomudationsMedia,
      wfhPropertyPhotos: state => state.wfhPropertyPhotos,
    }),
  },
  async created() {
    if (this.isProperty) {
      let wfhData = this.wfhPropertiesItem.wfaDataCapture

      this.propertiesData.step1.id = wfhData?.id
      this.propertiesPayload.id = wfhData?.id
      this.propertiesData.step1.name = wfhData?.name
      if (wfhData.wfaDataCaptureItems) {
        this.propertiesData.step1.description = getItemCollection(
          wfhData.wfaDataCaptureItems,
          "wfa_property_description"
        ).itemValue

        this.propertiesData.step2.wfa_property_open_from_date =
          getItemCollection(
            wfhData.wfaDataCaptureItems,
            "wfa_property_open_from_date"
          ).itemValue
        this.propertiesData.step2.wfa_property_open_to_date = getItemCollection(
          wfhData.wfaDataCaptureItems,
          "wfa_property_open_to_date"
        ).itemValue
        this.propertiesData.step2.wfa_amenity = getItemCollection(
          wfhData.wfaDataCaptureItems,
          "wfa_amenities"
        ).itemAdditionalInfo

        this.propertiesData.step3.wfa_service_additional_detail =
          getItemCollection(
            wfhData.wfaDataCaptureItems,
            "wfa_service_additional_detail"
          ).itemValue
        this.propertiesData.step3.wfa_service = getItemCollection(
          wfhData.wfaDataCaptureItems,
          "wfa_service_on_site"
        ).itemAdditionalInfo
        this.propertiesData.step3.wfa_service_1_5 = getItemCollection(
          wfhData.wfaDataCaptureItems,
          "wfa_service_1_5"
        ).itemAdditionalInfo
        this.propertiesData.step3.wfa_close_by_1_5 = getItemCollection(
          wfhData.wfaDataCaptureItems,
          "wfa_close_by_1_5"
        ).itemAdditionalInfo
        console.log("dataaaa", this.wfhPropertyPhotos)
        this.propertiesData.step4.media = this.wfhPropertyPhotos[0]?.rows
      }
    } else {
      let wfhData = this.wfhAccomudationsItem.wfaDataCapture
      this.accomodationData.step1.id = wfhData.id
      this.accommodationsPayload.id = wfhData.id
      this.accomodationData.step1.name = wfhData.name
      if (wfhData.wfaDataCaptureItems) {
        this.accomodationData.step1.description = getItemCollection(
          wfhData.wfaDataCaptureItems,
          "wfa_accommodation_description"
        ).itemValue
        this.accomodationData.step1.wfa_accommodation_feature =
          getItemCollection(
            wfhData.wfaDataCaptureItems,
            "wfa_accommodation_feature"
          ).itemValue

        this.accomodationData.step2.wfa_accommodation_price = getItemCollection(
          wfhData.wfaDataCaptureItems,
          "wfa_accommodation_price"
        ).itemAdditionalInfo

        this.accomodationData.step4.media = this.wfhAccomudationsMedia[0]?.rows
      }
    }
  },

  methods: {
    ...mapActions(WFH, {
      wfhSaveData: WFH_SAVE_DATA,
      saveImage: WFH_SAVE_ACCOMUDATION_PHOTOS,
      savePropertyImage: WFH_SAVE_PROPERTY_PHOTOS,
    }),
    async nextStep() {
      if (this.activeStep < this.stepperOptions.length) {
        let payload = {}
        if (this.isProperty) {
          this.propertiesPayload.wfaDataCaptureItems.length = 0
          if (this.activeStep === 0) {
            payload = this.$refs.propertyStep1.getPayload()
            this.propertiesPayload.wfaDataCaptureItems.push(payload)
          } else if (this.activeStep === 1) {
            payload = this.$refs.propertyStep2.getPayload()
            payload.forEach(element => {
              this.propertiesPayload.wfaDataCaptureItems.push(element)
            })
          } else if (this.activeStep === 2) {
            payload = this.$refs.propertyStep3.getPayload()
            payload.forEach(element => {
              this.propertiesPayload.wfaDataCaptureItems.push(element)
            })
          }
          await this.wfhSaveData(this.propertiesPayload)
        } else {
          this.accommodationsPayload.wfaDataCaptureItems.length = 0
          if (this.activeStep === 0) {
            payload = this.$refs.accommodationStep1.getPayload()
            payload.forEach(element => {
              this.accommodationsPayload.wfaDataCaptureItems.push(element)
            })
          } else if (this.activeStep === 1) {
            payload = this.$refs.accommodationStep2.getPayload()
            payload.forEach(element => {
              this.accommodationsPayload.wfaDataCaptureItems.push(element)
            })
          }
          await this.wfhSaveData(this.accommodationsPayload)
        }
        this.activeStep += 1
      } else {
        this.activeStep -= 1
      }
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep -= 1
      }
    },
    async doneStep() {
      if (!this.isProperty) {
        console.log("not")
        let payload = this.$refs.uploadMedia.getPayload()
        console.log("09876", payload)
        if (payload.length > 0) {
          let data = {
            accommodationId: this.accomodationData.step1.id,
            items: payload,
          }
          await this.saveImage(data)
        }
      } else {
        console.log("yes")
        let payload = this.$refs.uploadMediaProperty.getPayload()
        console.log("yufcur", payload)
        if (payload.length > 0) {
          let data = {
            propertyId: this.propertiesData.step1.id,
            items: payload,
          }
          await this.savePropertyImage(data)
        }
      }
      this.onClose()
    },
  },
}
</script>
<style src="../../../../assets/css/Stepper.css" scoped></style>
