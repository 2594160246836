import {
	WFH_PROPERTIES_FETCH_LIST,
	WFH_PROPERTIES_FETCH_ITEM,
	WFH_ACCOMUDATION_FETCH_ITEM,
	WFH_SAVE_DATA,
	WFH_SAVE_ACCOMUDATION_PHOTOS,
	WFH_FETCH_ACCOMUDATION_PHOTOS,
	WFH_DELETE_ACCOMUDATION_PHOTOS,
	WFH_SAVE_PROPERTY_PHOTOS,
	WFH_FETCH_PROPERTY_PHOTOS,
	WFH_DELETE_PROPERTY_PHOTOS,
	FETCH_CURRENCY_LIST,
} from "../store/mutations-type"
import {
	AUTH,
	API_LOADING
} from "../../auth/store/mutations-types"
import { getGridDataItemFirstCollection, getGridDataItemCollection } from "@/utils/api.utils"
  
const actions = {
	async [WFH_PROPERTIES_FETCH_LIST]({ commit }) {
		commit(`${AUTH}/${API_LOADING}`, true, {root: true})
		const response = await this.$repositories.wfh.wfhProperiesFetchList()
		const data = getGridDataItemFirstCollection(response)
		commit(WFH_PROPERTIES_FETCH_LIST, data)
		commit(`${AUTH}/${API_LOADING}`, false, {root: true})
	},
	async [WFH_PROPERTIES_FETCH_ITEM]({ commit }, id) {
		commit(`${AUTH}/${API_LOADING}`, true, {root: true})
		const response = await this.$repositories.wfh.wfhProperiesFetchItem(id)
		commit(WFH_PROPERTIES_FETCH_ITEM, response.data.result)
		commit(`${AUTH}/${API_LOADING}`, false, {root: true})
	},

	async [WFH_ACCOMUDATION_FETCH_ITEM]({ commit }, data) {
		commit(`${AUTH}/${API_LOADING}`, true, {root: true})
		const response = await this.$repositories.wfh.wfhAccomudationFetchItem(data.propertyId, data.accommodationId)
		commit(WFH_ACCOMUDATION_FETCH_ITEM, response.data.result)
		commit(`${AUTH}/${API_LOADING}`, false, {root: true})
	},

	async [WFH_SAVE_DATA]( { commit }, data) {
		commit(`${AUTH}/${API_LOADING}`, true, {root: true})
		await this.$repositories.wfh.wfhSaveData(data)
		commit(WFH_SAVE_DATA)
		commit(`${AUTH}/${API_LOADING}`, false, {root: true})
	},
	async [WFH_SAVE_ACCOMUDATION_PHOTOS](
		{ commit },
		data 
	) {
		commit(`${AUTH}/${API_LOADING}`, true, {root: true})
		await this.$repositories.wfh.updateAccomudaitonPhotos(data.accommodationId, data.items)
		commit(WFH_SAVE_ACCOMUDATION_PHOTOS)
		commit(`${AUTH}/${API_LOADING}`, false, {root: true})
	},
	async [WFH_SAVE_PROPERTY_PHOTOS](
		{ commit },
		data 
	) {
		commit(`${AUTH}/${API_LOADING}`, true, {root: true})
		await this.$repositories.wfh.updatePropertyPhotos(data.propertyId, data.items)
		commit(WFH_SAVE_PROPERTY_PHOTOS)
		commit(`${AUTH}/${API_LOADING}`, false, {root: true})
	},

	async [WFH_FETCH_ACCOMUDATION_PHOTOS]({commit}, accommodationId) {
		commit(`${AUTH}/${API_LOADING}`, true, {root: true})
		const response = await this.$repositories.wfh.wfhAccomudationFetchPhotos(accommodationId)
		const data = getGridDataItemCollection(response)
		commit(WFH_FETCH_ACCOMUDATION_PHOTOS, data)
		commit(`${AUTH}/${API_LOADING}`, false, {root: true})
	},
	async [WFH_FETCH_PROPERTY_PHOTOS]({commit}, propertyId) {
		commit(`${AUTH}/${API_LOADING}`, true, {root: true})
		const response = await this.$repositories.wfh.wfhPropertyFetchPhotos(propertyId)
		const data = getGridDataItemCollection(response)
		commit(WFH_FETCH_PROPERTY_PHOTOS, data)
		commit(`${AUTH}/${API_LOADING}`, false, {root: true})
	},
	async [WFH_DELETE_ACCOMUDATION_PHOTOS]({dispatch, commit}, input) {
		commit(`${AUTH}/${API_LOADING}`, true, {root: true})
		await this.$repositories.wfh.wfhAccomudationDeletePhotos(input)
		await dispatch(WFH_FETCH_ACCOMUDATION_PHOTOS, input.id) 
		commit(`${AUTH}/${API_LOADING}`, false, {root: true})
	},
	async [WFH_DELETE_PROPERTY_PHOTOS]({dispatch, commit}, input) {
		commit(`${AUTH}/${API_LOADING}`, true, {root: true})
		await this.$repositories.wfh.wfhPropertyDeletePhotos(input)
		await dispatch(WFH_FETCH_PROPERTY_PHOTOS, input.id)
		commit(`${AUTH}/${API_LOADING}`, false, {root: true})
	},

	async [FETCH_CURRENCY_LIST]({commit}) {
		commit(`${AUTH}/${API_LOADING}`, true, {root: true})
		const data = await this.$repositories.wfh.fetchCurrencyList()
		commit(FETCH_CURRENCY_LIST, data)
		commit(`${AUTH}/${API_LOADING}`, false, {root: true})
	},
}
  
export default actions
  