export default {
  "PAGES": {
    "LOGIN_PAGE": {
      "LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
      "REMEMBER_ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souviens-toi de moi"])},
      "ALREADY_HAVE_NOT_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas de compte ?"])},
      "SIGNUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrivez-vous"])}
    }
  },
  "MODALS": {
    
  },
  "VALIDATIONS": {
    
  }
}