<template>
  <div class="card-body p-3">
    <div class="row">
      <div class="col col-12 col-lg-8  mx-auto">
        <CustomStepper
        v-if="showStepper"
        :isProperty="true"
        :stepperOptions="stepperOptionsWizards"
        :onClose="onCloseWizard"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CustomStepper from "./components/CustomStepper.vue"
import {
  WFH,
  WFH_PROPERTIES_FETCH_ITEM,
  WFH_FETCH_PROPERTY_PHOTOS,
} from "../store/mutations-type"
import { mapActions, mapState } from "vuex"
export default {
  name: "WfhStepper",
  components: {
    CustomStepper,
  },
  data() {
    return {
      id: "",
      stepperOptionsWizards: [
        { title: "Description" },
        { title: "Open Dates" },
        { title: "Services" },
        { title: "Photos" },
      ],
      showStepper: false,
    }
  },
  computed: {
    ...mapState(WFH, {
      wfhPropertiesItem: state => state.wfhPropertiesItem,
    }),
  },
  async created() {
    this.id = this.$route.params.id
    await this.wfhProperiesFetchItem(this.id)
    await this.getPropertyImages(this.id)
    this.showStepper = true
  },
  methods: {
    ...mapActions(WFH, {
      wfhProperiesFetchItem: WFH_PROPERTIES_FETCH_ITEM,
      getPropertyImages: WFH_FETCH_PROPERTY_PHOTOS,
    }),
    onCloseWizard() {
      this.$router.push("/dashboard")
    },
  },
}
</script>
