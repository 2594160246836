<template>
  <div
    class="page-header align-items-start min-vh-100"
    style="background-image: url('https://i.ytimg.com/vi/e19GNYG_AZ4/maxresdefault.jpg');
    "
  >
  <LocaleSwitcher />
    <span class="mask bg-gradient-dark opacity-6" />
    <div class="container my-auto">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1 height-200"
              >
                <div class="m-auto">
				<h3 class="text-white font-weight-bolder text-center mt-50">Sign in
                </h3>
				</div>
              </div>
				<LoginForm @onFormSubmit="submitForm" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex"
import { mapActions } from "vuex"
import LoginForm from "@/modules/auth/views/components/forms/LoginForm"
import { AUTH, AUTH_LOGIN } from "@/modules/auth/store/mutations-types"
import { useI18n } from "vue-i18n"
import LocaleSwitcher from "../../../../components/LocaleSwitcher.vue"
import { get as _get } from "lodash"

export default {
	name: "LoginPage",
	components: {
		LoginForm,
		LocaleSwitcher,
	},
	setup() {
		const { t } = useI18n({
			inheritLocale: true,
			useScope: "local"
		})

		// Something todo ..

		return { t }
	},
	beforeMount() {
		this.toggleEveryDisplay()
		this.toggleHideConfig()
	},
	beforeUnmount() {
		this.toggleEveryDisplay()
		this.toggleHideConfig()
	},
	methods: {
		...mapActions(AUTH, [AUTH_LOGIN]),
		...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
		notifyWrongLogin() {
			this.$swal.fire({
				text: "Username or password is incorrect",
				type: "danger",
				showCloseButton: true,
			})
		},
		async submitForm(values) {
			this.isFormSending = true
			try {
				await this[AUTH_LOGIN](values)
				this.isFormSending = false
				const redirectTo = _get(this.$route, "query.redirect", "/dashboard")
				this.$router.push(redirectTo)
			} catch (e) {
				this.notifyWrongLogin()
				this.isFormSending = false
			}
		},
	},
}
</script>
<style scoped>
.mt-50 {
	margin-top: 50px !important;
}
</style>
