import { createApp } from "vue"
import App from "./App.vue"
import store from "./store"
import router, { routerHooksSetup } from "./router"
import "./assets/css/nucleo-icons.css"
import "./assets/css/nucleo-svg.css"
import VueSweetalert2 from "vue-sweetalert2"
import MaterialDashboard from "./material-dashboard"
import repositories from "@/repository/repositories-factory"
import { axiosInterceptorsSetup } from "@/config/axios.config"
import i18n from "./i18n"
import TableLite from "vue3-table-lite"



const appInstance = createApp(App).use(i18n).use(TableLite)
store.$repositories = repositories
axiosInterceptorsSetup(store)
appInstance.use(store)
appInstance.use(router)
routerHooksSetup(store)
appInstance.use(VueSweetalert2)
appInstance.use(MaterialDashboard)
// appInstance.component("EasyDataTable", Vue3EasyDataTable)
appInstance.mount("#app")
console.log("instance", appInstance)
export default appInstance