export default {
  "PAGES": {
    "LOGIN_PAGE": {
      "INICIAR SESIÓN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
      "REMEMBER_ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuérdame"])},
      "ALREADY_HAVE_NOT_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿No tienes una cuenta?"])},
      "SIGNUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse"])}
    }
  },
  "MODALS": {
    
  },
  "VALIDATIONS": {
    
  }
}