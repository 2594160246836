import {
	USER_UPDATE,
	USER_UPDATE_ENTITIES,
} from "@/modules/user/store/mutations-types"

const mutations = {
	[USER_UPDATE_ENTITIES](state, payload) {
		state.userEntities = payload
	},
	[USER_UPDATE](state, payload) {
		state.user = payload
	},
}

export default mutations
