import {
	WFH_PROPERTIES_FETCH_LIST,
	WFH_PROPERTIES_FETCH_ITEM,
	WFH_ACCOMUDATION_FETCH_ITEM,
	WFH_FETCH_ACCOMUDATION_PHOTOS,
	WFH_FETCH_PROPERTY_PHOTOS,
	FETCH_CURRENCY_LIST,
} from "../store/mutations-type"
  
const mutations = {
	[WFH_PROPERTIES_FETCH_LIST](state, payload) {
		state.wfhPropertiesList = payload
	},
	[WFH_PROPERTIES_FETCH_ITEM](state, payload) {
		state.wfhPropertiesItem = payload
	},
	[WFH_ACCOMUDATION_FETCH_ITEM](state, payload) {
		state.wfhAccomudationsItem = payload
	},
	[WFH_FETCH_ACCOMUDATION_PHOTOS](state, payload) {
		state.wfhAccomudationsMedia = payload
	},
	[WFH_FETCH_PROPERTY_PHOTOS](state, payload) {
		state.wfhPropertyPhotos = payload
	},
	[FETCH_CURRENCY_LIST](state, payload) {
		state.currencyList = payload
		console.log("payl", payload)
	}
}
export default mutations