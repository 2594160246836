<template>
  <div v-if="!pageIsLogin" class="loader-container card p-4 border-primary">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: "CustomSpinner",
  data() {
    return {}
  },
  computed: {
    pageIsLogin() {
      return this.$route.name === "Signin"
    }
  },
}
</script>
<style lang="scss" scoped>
.loader-container {
  position: fixed;
  top: 45%;
  left: 54%;
  transform: translate(-50%, -50%);
  z-index: 100;
  .loader {
    border: 6px solid #f3f3f3; /* Light grey */
    border-top: 6px solid #43a047; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
