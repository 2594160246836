import axios from "axios"
import { get as _get } from "lodash"
import appInstance from "@/main.js"

import TokenService from "../services/token.service"
import { AUTH, AUTH_LOGOUT } from "@/modules/auth/store/mutations-types"

const axiosApiInstance = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	// withCredentials: true,
})

export const axiosInterceptorsSetup = $store => {
	axiosApiInstance.interceptors.response.use(
		response => {
			const token = TokenService.getAccessTokenFromHeader(response)
			if (token) TokenService.saveAccessToken(token)
			const dataStatus = _get(response, "data.status", null)
			var param = "data.errors.message"
			if ((((response || {}).data || {}).errprs || {}).detail) {
				param = "data.errors.detail"
			}
			const dataErrorMessage = _get(
				response,
				param,
				"API error without message"
			)
			if (dataStatus === "SERVER_ERROR") {
				appInstance.$swal.fire({
					html: new Error(dataErrorMessage),
					type: "danger",
					title: dataStatus,
					showCloseButton: true,
				})
				if (dataStatus === "BAD_REQUEST")
					return Promise.reject(new Error(dataErrorMessage))
				return
			}

			return response
		},
		async function(error) {
			const errorResponse = error.response
			const isUnauthorized = errorResponse.status === 401 || errorResponse.status === 403
			if (isUnauthorized) {
				const { data } = errorResponse
				if (data.expired) {
					return TokenService.refreshToken(error)
				} else {
					$store.dispatch(`${AUTH}/${AUTH_LOGOUT}`)
					appInstance.$router.push("/login")
				}
			}

			return Promise.reject(error)
		}
	)
}

export default axiosApiInstance