<template>
    <div class="locale ">
        <select v-model="$i18n.locale">
            <option v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale">
                {{ locale }}
            </option>
        </select>
    </div>
  </template>
  
<script>
export default {
	name: "LocaleSwitcher",
	data() {
		return { locales: ["du", "en", "fr", "gr", "it", "sp"] }
	}
}
</script>
<style scoped>
.locale {
    z-index: 9999;
    position: fixed;
    right: 0px;
}
select {
        width: 70px;
        height: 30px;
}
</style>
  