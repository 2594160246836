import clientAxios from "@/config/axios.config"
import AuthRepository from "@/modules/auth/auth.repository"
import UserRepository from "@/modules/user/user.repository"
import WfhRepository from "@/modules/wfh/wfh.repository"

export default {
	auth: AuthRepository(clientAxios)("/campsited/partner/login"),
	user: UserRepository(clientAxios)("/campsited/partner"),
	wfh: WfhRepository(clientAxios)("/campsited/partner"),
}
