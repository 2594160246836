export default {
  "PAGES": {
    "LOGIN_PAGE": {
      "LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
      "REMEMBER_ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember me"])},
      "ALREADY_HAVE_NOT_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account?"])},
      "SIGNUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])}
    }
  },
  "MODALS": {
    
  },
  "VALIDATIONS": {
    
  }
}