import {
	AUTH_INIT,
	AUTH_LOGIN,
	AUTH_LOGOUT,
	API_LOADING,
} from "@/modules/auth/store/mutations-types"
import TokenService from "@/services/token.service"
import HttpService from "@/services/http.service"
import { USER, USER_UPDATE } from "@/modules/user/store/mutations-types"
import { getUserFromToken } from "@/modules/user/utils/user.utils"

const actions = {
	async [AUTH_LOGIN]({ dispatch }, credentials) {
		dispatch(API_LOADING, true)
		return this.$repositories.auth.login(credentials).then(res => {
			const accessToken = TokenService.getAccessTokenFromHeader(res)
			const user = getUserFromToken(accessToken)

			if (accessToken) {
				dispatch(AUTH_INIT, { user, token: accessToken })
			}
			dispatch(API_LOADING, false)
		})
	},

	[AUTH_LOGOUT]({ commit }) {
		TokenService.clearStorageTokens()
		HttpService.removeAuthorizationHeader()
		commit(AUTH_LOGOUT)
	},

	[AUTH_INIT]({ commit, }) {
		const token = TokenService.getAccessTokenFromStorage()
		HttpService.setAuthorizationHeader(token)
		const user = getUserFromToken(token)
		commit(AUTH_LOGIN, token)
		commit(`${USER}/${USER_UPDATE}`, user, { root: true })
	},
	[API_LOADING]({ commit }, status) {
		commit(API_LOADING, status)
	},
}

export default actions
