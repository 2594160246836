<template>
    <div class="card-body p-3">
      <div class="row">
          <CustomStepper v-if="showStepper" :isProperty="false" :stepperOptions="stepperOptionsWizards" :onClose="onCloseWizard"/>
      </div>
    </div>
</template>

<script>
import CustomStepper from "./components/CustomStepper.vue"
import { WFH, WFH_ACCOMUDATION_FETCH_ITEM, WFH_FETCH_ACCOMUDATION_PHOTOS } from "../store/mutations-type"
import { mapActions, mapState } from "vuex"
export default {
	name: "WfhStepper",
	components: {
		CustomStepper,
	},
	data() {
		return {
			stepperOptionsWizards: [
				{ title: "Description" },
				{ title: "Pricing" },
				{ title: "Photos" },
			],
			showStepper: false
		}
	},
	computed: {
		...mapState(WFH, {
			wfhAccomudationsItem: state => state.wfhAccomudationsItem,
		}),
	},
	async created() {
		await this.wfhAccomudationsFetchItem({propertyId: this.$route.params.pId, accommodationId: this.$route.params.accId})
		await this.getAccomudationImages(this.$route.params.accId)
		this.showStepper = true

	},
	methods: {
		...mapActions(WFH, {
			wfhAccomudationsFetchItem: WFH_ACCOMUDATION_FETCH_ITEM,
			getAccomudationImages: WFH_FETCH_ACCOMUDATION_PHOTOS
		}),
		onCloseWizard() {
			this.$router.push("/dashboard")
		},
	},
}
</script>
