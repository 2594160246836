import jwt_decode from "jwt-decode"

import LocalStorageService from "@/services/local-storage.service"
import HttpService from "@/services/http.service"
import axiosApiInstance from "@/config/axios.config"

export const LOCAL_STORAGE_TOKEN = "accessToken"
export const LOCAL_STORAGE_REFRESH_TOKEN = "refreshToken"

class TokenService {
	constructor({
		LocalStorageService,
		HttpService,
		axiosApiInstance,
		jwtDecode,
	}) {
		this.localStorageService = LocalStorageService
		this.httpService = HttpService
		this.axios = axiosApiInstance
		this.jwtDecode = jwtDecode
	}

	getDecodedToken(token) {
		const accessToken = token || this.getAccessTokenFromStorage()
		if (!accessToken) return null

		return this.jwtDecode(accessToken)
	}

	saveAccessToken(token) {
		this.httpService.setAuthorizationHeader(token)
		this.setAccessTokenToStorage(token)
	}

	setAccessTokenToStorage(token) {
		this.localStorageService.set(LOCAL_STORAGE_TOKEN, token)
	}

	getAccessTokenFromHeader(response) {
		return response.headers["authorization"]
	}

	getAccessTokenFromStorage() {
		return this.localStorageService.get(LOCAL_STORAGE_TOKEN)
	}

	getRefreshTokenFromStorage() {
		return this.localStorageService.get(LOCAL_STORAGE_REFRESH_TOKEN)
	}

	clearStorageTokens() {
		this.localStorageService.remove(LOCAL_STORAGE_TOKEN)
		this.localStorageService.remove(LOCAL_STORAGE_REFRESH_TOKEN)
	}

	async refreshToken(error) {
		let subscribers = []
		let isAlreadyFetchingAccessToken = false

		const onAccessTokenFetched = access_token => {
			subscribers.forEach(callback => callback(access_token))
			isAlreadyFetchingAccessToken = false
			subscribers = []
		}

		const addSubscriber = callback => {
			subscribers.push(callback)
		}

		try {
			const { response: errorResponse } = error
			const refreshToken = this.getRefreshTokenFromStorage()

			if (!refreshToken) return Promise.reject(error)
			const retryOriginalRequest = new Promise(resolve => {
				addSubscriber(access_token => {
					const originalRequest = errorResponse.config

					this.httpService.setAuthorizationHeader(
						access_token,
						originalRequest.headers
					)
					resolve(this.axios(originalRequest))
				})
			})

			if (!isAlreadyFetchingAccessToken) {
				isAlreadyFetchingAccessToken = true
				// use vuex action to refresh
				// const response = await axiosApiInstance({
				//   method: "post",
				//   url: `/auth/refresh-token/`,
				//   data: {
				//     token: refreshToken,
				//   },
				// })

				// if (!response.data) {
				//   return Promise.reject(error)
				// }
				// const newAccessToken = this.getAccessTokenFromHeader(response)
				onAccessTokenFetched(true)
			}
			return retryOriginalRequest
		} catch (err) {
			return Promise.reject(err)
		}
	}
}

export default new TokenService({
	LocalStorageService,
	HttpService,
	axiosApiInstance,
	jwtDecode: jwt_decode,
})
