import actions from "../store/actions"
import mutations from "../store/mutations"

const state = () => ({
	wfhPropertiesList: { "columns": [], "rows" : [], "numberOfRows": 0} ,
	wfhPropertiesItem: {},
	wfhAccomudationsItem: {},
	wfhAccomudationsMedia: [],
	wfhPropertyPhotos: [],
	currencyList: [],
})

const getters = {}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}