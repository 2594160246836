<template>
  <div class="card mb-4">
    <div class="d-flex">
      <div
        class="icon icon-lg bg-gradient-success shadow text-center border-radius-xl mt-n3 ms-4"
      ></div>
      <h6 class="mt-3 mb-2 ms-3">{{ title }}</h6>
    </div>
    <div class="card-body p-3">
      <div class="row">
        <div class="table-responsive pt-5">
          <table-lite
            :is-slot-mode="true"
            :is-loading="table.isLoading"
            :columns="packageTableColumns"
            :rows="table.rows"
            :total="table.totalRecordCount"
            :sortable="table.sortable"
            :messages="table.messages"
            :pageOptions="table.pageOptions"
            page-size="5"
            @do-search="doSearch"
            @is-finished="table.isLoading = false"
            @row-clicked="onRowClick"
          >
            <template v-slot:action="data">
              <MaterialFab @click="onEditClick(data)">
                <i class="material-icons opacity-10">edit</i>
              </MaterialFab>
            </template>
          </table-lite>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue"
import TableLite from "vue3-table-lite"
import BookingsTableMixin from "../../mixins/bookings-table.mixin"
import MaterialFab from "@/components/MaterialFab.vue"

export default defineComponent({
  name: "UinersalTable",
  mixins: [BookingsTableMixin],
  components: { TableLite, MaterialFab },
  props: {
    title: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    data: function (newVal) {
      this.table.totalRecordCount = newVal.numberOfRows
    },
  },
  mounted() {
    let item = document.querySelector(".vtl-table")
    if (item) {
      item.querySelectorAll(".vtl-tbody-tr").forEach(btn => {
        btn.onclick = function () {
          item.querySelectorAll(".vtl-tbody-tr").forEach(tr => {
            tr.classList.remove("selected_row")
          })
          this.classList.add("selected_row")
        }
      })
    }
    this.doSearch(0, 5)
  },
  computed: {
    dataTable() {
      return this.data
    },
    dataTableTotalRecordCount() {
      return this?.data.numberOfRows
    },
  },
  data() {
    return {
      table: {
        pageOptions: [
          { value: 5, text: 5 },
          { value: 10, text: 10 },
          { value: 25, text: 25 },
          { value: 50, text: 50 },
        ],
        isReSearch: false,
        isLoading: false,
        columns: [],
        rows: [],
        totalRecordCount: this?.data.numberOfRows,
        sortable: {
          order: "id",
          sort: "asc",
        },
      },
    }
  },
  methods: {
    onRowClick(row) {
      this.$emit("onRowSelect", row)
    },

    onEditClick(row) {
      this.$emit("onEditClick", row.value)
    },
    doSearch(offset, limit) {
      this.table.isReSearch = offset == undefined ? true : false
      this.table.rows = this.packageTableRow.slice(offset, offset + limit)
    },
  },
})
</script>

<style scoped>
::v-deep(.vtl-table .vtl-thead .vtl-thead-th) {
  color: #7b809a;
  border: none;
  background-color: #fff;
  border-color: #f0f2f5;
  border-bottom: 1pt solid #f0f2f5;
}
::v-deep(.vtl-table td),
::v-deep(.vtl-table tr) {
  color: #7b809a;
  border: none;
  border-bottom: 1pt solid #f0f2f5;
}
::v-deep(.vtl-paging-info) {
  color: #7b809a;
}
::v-deep(.vtl-paging-count-label),
::v-deep(.vtl-paging-page-label) {
  color: #7b809a;
}
::v-deep(.vtl-paging-change-div) {
  position: absolute !important;
  top: -47px !important;
}
::v-deep(.vtl-paging-pagination-page-link) {
  border: none;
}
::v-deep(.vtl-paging-count-dropdown) {
  border-color: #f0f2f5;
  border-radius: 0.375rem;
  width: 54px;
  height: 45px;
  margin-left: 5px;
}
::v-deep(.vtl-paging-count-label),
::v-deep(.vtl-paging-page-label),
::v-deep(.vtl-paging-page-dropdown) {
  display: none;
}
::v-deep(.vtl-paging) {
  justify-content: space-between;
}
::v-deep(.selected_row) {
  background-color: #66bb6a !important;
}
::v-deep(.vtl-paging-pagination-page-li-number.page-item.disabled a) {
  color: #fff !important;
  background-color: #66bb6a;
}
</style>
