<template>
  <div
    class="pt-3 bg-white multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
    <div class="multisteps-form__content">
      <div class="mt-3 row">
        <div class="col-12">
          <label class="form-control mb-0">Product images</label>
          <div class="media-list">
            <div class="media-item" v-for="item in oldMedia" :key="item.name">
              <div class="container">
                <img class="media-image image" :src="getImageUrl(item)" />
                <div class="overlay" @click="deletePhoto(item)">
                  <a href="#" class="icon" title="User Profile">
                    <i class="fa fa-close"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
         
          <div
            id="productImg"
            action="/file"
            class="form-control border dropzone"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropzone from "dropzone"
import { WFH, WFH_SAVE_ACCOMUDATION_PHOTOS, WFH_DELETE_ACCOMUDATION_PHOTOS, WFH_DELETE_PROPERTY_PHOTOS } from "../../store/mutations-type"
import { mapActions, mapState } from "vuex"
import { getItemCollection } from "@/utils/api.utils"

export default {
	name: "CustomMedia",
	props: {
		step4: {
			type: Object,
		},
		id: {
			type: String,
		},
		isProperty: {
			type: String,
		}
	},
	data() {
		return {
			oldMedia: [],
			images: [],
		}
	},
	computed: {
		...mapState(WFH, {
			wfhPropertyPhotos: state => state.wfhPropertyPhotos,
			wfhAccomudationsMedia: state => state.wfhAccomudationsMedia
		}),
	},
	mounted() {
		this.oldMedia = this.step4?.media
		let myDropzone = new Dropzone("#productImg")

		document.onclick = event => {
			if (event.target.matches(".dz-success-mark") || event.target.matches(".dz-error-mark")) {
				if (event.target.parentElement.classList.contains("dz-preview")) {
					let index = event.target.parentElement.dataset.index
					this.images.splice(index, 1)
					event.target.parentElement.remove()
				}
				if (document.querySelectorAll(".dz-preview").length == 0) {
					document.querySelector(".dz-message").style.display = "block"
				}
			}
		}

		myDropzone.on("addedfile", (file) => {
			document.querySelectorAll(".dz-preview").forEach((element, index) => {
				element.dataset.index = index
			})
			document.querySelector(".dz-message").style.display = "none"
			this.images.push(file)
		})
	},
	methods: {
		...mapActions(WFH, {
			wfhSaveData: WFH_SAVE_ACCOMUDATION_PHOTOS,
			wfhDeleteAccomodationPhoto: WFH_DELETE_ACCOMUDATION_PHOTOS,
			wfhDeletePropertyPhoto: WFH_DELETE_PROPERTY_PHOTOS,
		}),
		getPayload() {
			return this.images
		},
		getImageUrl(item){
			return getItemCollection(
				item.row , "url"
			).value
		},
		async deletePhoto(photoId) {
			console.log("item", photoId)
			let input={
				id: this.id,
				keys: [photoId.row[0].value]
			}
			console.log("payload", input)
			if(!this.isProperty){
				await this.wfhDeleteAccomodationPhoto(input)
				this.oldMedia = this.wfhAccomudationsMedia[0]?.rows
			}
			else {
				await this.wfhDeletePropertyPhoto(input)
				this.oldMedia = this.wfhPropertyPhotos[0]?.rows
			}
			// this.oldMedia = []
		}
	}
}
</script>
<style scoped>
.media-list {
  overflow: auto;
  margin: 11px 0px;
}
.media-item {
  width: 120px;
  display: inline-block;
}
.container {
  position: relative;
  padding: 0px;
}

.image {
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 20px;
  border: 1px solid #c9cccf;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .3s ease;
  background-color: transparent;
}

.container:hover {
	opacity: 0.7;
}

.container:hover .overlay {
  opacity: 1;
}

.icon {
  color: #66bb6a;
  font-size: 40px;
  position: absolute;
  top: 40%;
  left: 40%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.fa-user:hover {
  color: #ffffff;
}
</style>
